import "./index.css";

export default function LoadingIndicator() {
  return (
    <div className="w-full h-full bg-white bg-opacity-70 z-[5000] fixed flex justify-center items-center left-0 top-0">
      <div data-testid="loader" className="loader" />
    </div>
  );
}

export function LocalLoadingIndicator() {
  return (
    <div className="w-full h-full bg-white bg-opacity-70 fixed flex justify-center items-center">
      <div data-testid="loader" className="loader" />
    </div>
  );
}
